import consumer from "./consumer"


document.addEventListener('turbo:load', () => {
    const maintenanceDivs = document.querySelectorAll('[data-channel-subscribe="maintenance-request"]')
    if (maintenanceDivs.length > 0) {
        maintenanceDivs.forEach(function(element, i) {
            const chatList = document.getElementById('comment-list');
            const form = document.getElementById('new-comment');
            const maintenance_request_id = element.dataset.maintenanceRequestId
    
            consumer.subscriptions.create({
                channel: "MaintenanceRequestChannel",
                maintenance_request: maintenance_request_id
            }, {
                received: function(data) {
                    chatList.insertAdjacentHTML('beforeend', data.message);
                    form.reset();
                    chatList.scrollTop = chatList.scrollHeight
                }
            })
        });
    } 
});