import consumer from "./consumer"

document.addEventListener('turbo:load', () => {
    const divs = document.querySelectorAll('[data-channel-subscribe="conversation"]')
    divs.forEach(function(element, i) {
        const chatList = document.getElementById('message_list');
        const form = document.getElementById('new_message');
        const conversation_id = element.dataset.conversationId
        const user_id = element.dataset.userId

        consumer.subscriptions.create({
            channel: "MessageChannel",
            conversation: conversation_id
        }, {
            received: function(data) {
                if (data.sender_id == user_id) {
                        chatList.insertAdjacentHTML('beforeend', data.sender);
                    } else {
                        chatList.insertAdjacentHTML('beforeend', data.receiver);
                }
                form.reset();
                chatList.scrollTop = chatList.scrollHeight
            }
        })
    });
});