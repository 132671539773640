import consumer from "./consumer"


document.addEventListener('turbo:load', () => {
    const leaseDivs = document.querySelectorAll('[data-channel-subscribe="lease"]')
    if (leaseDivs.length > 0) {
        leaseDivs.forEach(function(element, i) {
            const chatList = document.getElementById('comment-list');
            const form = document.getElementById('new-comment');
            const lease_id = element.dataset.leaseId
    
            consumer.subscriptions.create({
                channel: "LeaseChannel",
                lease: lease_id
            }, {
                received: function(data) {
                    chatList.insertAdjacentHTML('beforeend', data.message);
                    form.reset();
                    chatList.scrollTop = chatList.scrollHeight
                }
            })
        });
    }
});