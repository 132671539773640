import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status", "value"];
  static values = {
    url: String,
    documentId: String,
  };

  initialize() {
    this.load();
  }

  load() {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        document_uuid: this.documentIdValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.statusTarget.classList.remove("animate-pulse", "bg-gray-400");
        this.handleResponse(data);
      });
  }

  handleResponse(data) {
    if (data === "pending") {
      this.statusTarget.classList.add("w-2/3", "bg-blue-600");
      this.valueTarget.innerHTML = "Odottaa allekirjoituksia";
    } else if (data === "signed") {
      this.statusTarget.classList.add("w-full", "bg-blue-600");
      this.valueTarget.innerHTML = "Allekirjoitettu";
    } else if (data === "new") {
      this.statusTarget.classList.add("w-1/3", "bg-blue-600");
      this.valueTarget.innerHTML = "Lähetetty";
    }
  }
}
