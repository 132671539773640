import CharacterCounter from "stimulus-character-counter"

export default class extends CharacterCounter {

  static values = {
    fieldCount: Number
  }

  connect() {
    super.connect() 
  }
  update() {
    super.update()
    if (this.count >= this.fieldCountValue) {        
        this.counterTarget.classList.add("text-green-600")
    } else {
        this.counterTarget.classList.remove("text-green-600")
    }
  }
}
