export const elements = [
    'calendarContainer',
    'currentYearElement',
    'days',
    'daysContainer',
    'input',
    'nextMonthNav',
    'monthNav',
    'prevMonthNav',
    'rContainer',
    'selectedDateElem',
    'todayDateElem',
    'weekdayContainer'
  ]