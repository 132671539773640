import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "total", "sum"];

    initialize() {
        this.update = this.update.bind(this)
    }

    connect() {
        this.update()
        this.inputTarget.addEventListener('input', this.update)
    }

    disconnect() {
        this.inputTarget.removeEventListener('input', this.update)
    }

    update() {
        this.totalTarget.innerHTML = this.calculate
        this.sumTarget.innerHTML = this.sum
    }

    get calculate() {
        return (Math.round((this.inputTarget.value * 0.039) * 10) / 10).toString().replace(".", ",");
    }

    get sum() {
        return (Math.round((Number(this.inputTarget.value) + (Number(this.inputTarget.value) * 0.039)) * 10) / 10).toString().replace(".", ",");
    }
}
