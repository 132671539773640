import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "@hotwired/turbo-rails"
import "./controllers"
import "./channels"
import "alpine-turbo-drive-adapter"
import Alpine from 'alpinejs'

window.Alpine = Alpine
Alpine.start()

window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })
  window.dispatchEvent(event)
}

document.addEventListener('turbo:before-cache', () => {
  const flash_message_element = document.querySelector(".flash")
  if (flash_message_element) {
    flash_message_element.remove()
  }
});
